
import Footer from "@/components/layout/navigation/Footer"
import defaultMeta from "@/mixins/defaultMeta"
import { mapGetters } from "vuex"
import traineeNavigationMixin from "@/mixins/traineeNavigationMixin"

/**
 * Mobile: smAndDown
 */
export default {
    name: "CustomdomainLayout",
    components: {
        CookieInformation: () =>
            import("@/components/layout/CookieInformation"),
        SVGGradient: () => import("@/components/base/design/SVGGradient"),
        ImpersonateHeader: () => import("@/components/admin/ImpersonateHeader"),
        ProgressCircular: () => import("@/components/base/ProgressCircular"),
        Footer,
    },
    mixins: [defaultMeta, traineeNavigationMixin],
    computed: {
        ...mapGetters("cookie", ["showCookieConsent"]),
        ...mapGetters("ui", ["loadingSpinner"]),
        ...mapGetters("meta", ["meta"]),
        showFooter() {
            return (
                !(this.$auth.loggedIn && this.$breakpoint.mdAndUp) ||
                this.meta.footer?.persistent
            )
        },
        mainStyle() {
            /* Make main at least 100vh being white */
            const { footer, insetFooter, bottom } = this.$vuetify.application
            return {
                "min-height": `calc(100vh + ${
                    footer + insetFooter + bottom
                }px)`,
            }
        },
        keepAlivePages() {
            const pages = [
                "AusbildungLocationSlug",
                "PraktikumLocationSlug",
                "Chat",
            ]
            if (this.$can.business()) {
                pages.push(new RegExp("Betrieb.*"))
            }
            return pages
        },
        noKeepAlivePages() {
            const pages = []
            if (this.$can.business()) {
                pages.push("betriebe-slug-jobs-bearbeiten")
            }
            return pages
        },
    },
}
